<template>
  <div class="row-fluid csn-game-play">
    <div class="row container-game-play" v-if="IS_GAME_PLAY_PENDING">
      <Loader />
    </div>

    <div :class="gameContainerClassList" v-else-if="existsGame">
      <div v-if="hasTimer" class="csn-game-play-timer-container">
        <Time />
      </div>

      <iframe v-if="hasIframe" :src="game.responseIframe"></iframe>
      <Message :message="t('no_iframe')" :type="msgType" v-else />
      <!-- <div class="col-md-12">
        <p class="alert alert-danger">{{ message }}</p>
      </div>
       -->

      <div class="option-buttons csn-option-buttons">
        <ul>
          <li>
            <a
              href="#"
              class="btn casino-btn casino-btn-default casino-buttons"
              @click="toggleFullScreenMode"
            >
              <span class="casino-icon casino-icon-full-size"></span>
            </a>
          </li>
          <li>
            <FavoriteIcon
              :isPending="IS_GAME_FAV_STATUS_PENDING"
              :isFavorite="game.isFavorite"
              @click="toggleFavourite"
            />
          </li>
        </ul>
      </div>
    </div>
    <GamePlayNavigation />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  Module,
  GAME_PLAY_DICTIONARY,
  IS_GAME_PLAY_PENDING,
  UPDATE_GAME_FAVOURITE_STATUS,
  GAME_PLAY,
  EMPTY_STRING,
  SET_IS_PENDING,
  SET_GAME_PLAY_DICTIONARY,
  IS_GAME_FAV_STATUS_PENDING,
} from '@/constants'
import { isNotNil, commitMutation } from '@/helpers'
import { gameMixin } from '@/mixins'

export default {
  name: GAME_PLAY,
  components: {
    Loader: () => import('@/components/Loader'),
    Message: () => import('@/components/atoms/Message'),
    GamePlayNavigation: () => import('./components/GamePlayNavigation'),
    Time: () => import('@/components/Time'),
    FavoriteIcon: () => import('@/components/FavoriteIcon'),
  },
  mixins: [gameMixin],
  data() {
    return {
      message: EMPTY_STRING,
    }
  },
  computed: {
    ...mapState(Module.GAME_PLAY, [
      GAME_PLAY_DICTIONARY,
      IS_GAME_PLAY_PENDING,
      IS_GAME_FAV_STATUS_PENDING,
    ]),
    game() {
      return this.GAME_PLAY_DICTIONARY?.[this.gameSlug]
    },
    existsGame() {
      return isNotNil(this.game)
    },
    hasIframe() {
      return !!this.game?.responseIframe
    },
    hasTimer() {
      return this.game.realClock
    },
  },
  methods: {
    ...mapActions(Module.GAME_PLAY, [
      UPDATE_GAME_FAVOURITE_STATUS,
      SET_GAME_PLAY_DICTIONARY,
    ]),
    async toggleFavourite() {
      const id = this.game.id
      const isFavorite = !this.game.isFavorite

      this.UPDATE_GAME_FAVOURITE_STATUS({
        urlSlug: this.gameSlug,
        isFavorite,
        id,
      })
    },
  },
  beforeRouteUpdate(to, from, next) {
    commitMutation([Module.MAIN, SET_IS_PENDING], true)
    this.SET_GAME_PLAY_DICTIONARY({
      urlSlug: to.params.slug,
    }).then(() => {
      commitMutation([Module.MAIN, SET_IS_PENDING], false)
      next()
    })
  },
}
</script>
